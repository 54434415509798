<template>
  <div class="main">
    <div class="container">
      <div class="news_header">
        <div class="title">Legal Information</div>
      </div>
      <div class="news_detail">
        <div class="text">
          ECIawards.org and our policies are provided with the purpose of
          helping you with compliance. We are not liable for any content, code,
          or other errors or omissions or inaccuracies if there is any error. We
          welcome you to report to us if anything not proper.
          <br /><br />While we do our best to provide you useful information to
          use as a starting point. <br /><br />
          Our website content provides no warranties or guarantees. Nothing in
          this website, therefore, shall be considered to be against with our
          mistakes. Please note that in some cases, depending on your own
          judgement of using our web site.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'text',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/news.scss';
</style>
